(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ELEMENT"), require("Vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["ELEMENT", "Vue", "axios"], factory);
	else if(typeof exports === 'object')
		exports["avueData"] = factory(require("ELEMENT"), require("Vue"), require("axios"));
	else
		root["avueData"] = factory(root["ELEMENT"], root["Vue"], root["axios"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__5f72__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_cebe__) {
return 